import React from 'react';
import { Link } from 'react-router-dom';

import FooterLogo from './Img/main-logo.png';
import FaceBookIcon from './Img/facebook-icon1.svg';
import InstagramIcon from './Img/instagram-icon1.svg';
import LinkedinIcon from './Img/linkedin-icon1.svg';
import WhatsappIcon from './Img/whatsapp-icon.svg';

const Footer = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
  return (
    <footer className='site-footer'>
        <div className='site-container'>
            <div className='footer-content'>
              
                <div className='Foot_part'>
                    <h4>What we do</h4>
                    <ul>
                    <li>
                        <Link to="/caling-handling-management-solutions">Calling Handling & Management Solutions</Link>
                        </li>
                        <li>
                        <Link to="/recruitment-services">Recruitment Services</Link>
                        </li>
                        <li>
                        <Link to="/consultation-services">Consultation Services</Link>
                        </li>
                        <li>
                        <Link to="/business-support-services">Business Support Services</Link>
                        </li>
                    </ul>

                </div>

                <div className='Foot_part'>
                    <h4>Quick Links</h4>
                    <ul>
                        <li><Link to='/about'>About Us</Link></li>
                        <li><Link to='/careers'>Careers</Link></li>
                        <li><Link to='/news'>News and Blogs</Link></li>
                        <li><Link to='/contact-us'>Contact us</Link></li>
                    </ul>
                </div>

                <div className='Foot_part'>
                    <h4>Copyright & Legal</h4>
                    <ul>
                        <li><Link to='/terms'>Terms and Conditions</Link></li>
                        <li><Link to='/privacy'>Privacy Policy</Link></li>
                        <li><Link to='/cookie-policy'>Cookie Policy</Link></li>
                    </ul>
                </div>

                <div className='Foot_part Fst_Foot_part'>
                    <Link to='/' className='foot-logo'>
                        <img src={FooterLogo} alt='Footer logo' />
                    </Link>
                    <div className='Ffa_PaG'>
                    <p>
                              <span>UK:</span> 
                            <a href='tel:+442038905470' aria-label='Call us at  +442038905470'> +442038905470</a>
                            </p>
                            <p>
                              <span>US:</span> 
                            <a href='tel:+19452922128' aria-label='Call us at +19452922128'> +19452922128</a>
                            </p>
                            <p>
                            <span>Email:</span>
                            <a href='mailto:info@simu-l.com' aria-label='Email us at info@simu-l.com'>info@simu-l.com</a>
                            </p>
                            
                    <div className='FootNav_Social_icons'>
                <p>Connect with us</p>
                        <div className='social_Icons_main'>
                            <a href='https://www.facebook.com/share/CQ7FPXEJJ3osiPaS/' aria-label='Facebook' target='_blank' rel='noopener noreferrer'>
                                <img src={FaceBookIcon} alt='Facebook Icon' />
                            </a>
                            <a href='https://www.instagram.com/simulmgt?igsh=MjRrcGw3MWEwYXk1' aria-label='Instagram' target='_blank' rel='noopener noreferrer'>
                                <img src={InstagramIcon} alt='Instagram Icon' />
                            </a>
                            <a href='https://www.linkedin.com' aria-label='LinkedIn' target='_blank' rel='noopener noreferrer'>
                                <img src={LinkedinIcon} alt='LinkedIn Icon' />
                            </a>
                            <a href='https://wa.me/+447375557227' aria-label='WhatsApp' target='_blank' rel='noopener noreferrer'>
                                <img src={WhatsappIcon} alt='WhatsApp Icon' />
                            </a>

                        </div>
                        </div>
                       
                        <p className='aalk-Ffa'>© {currentYear} SIMUL Management Solutions</p>
                        <p>All rights Reserved</p>
                        {/* <p style={{ fontSize: '12px' }}>
                            Developed by <a href='https://prolianceltd.com/' style={{ textDecoration: 'underline', color: '#6d1c0f', fontWeight: 'bold' }}>Proliance LTD</a>
                            </p> */}


                    </div>
                </div>
                
            </div>
        </div>
    </footer>
  );
};

export default Footer;
